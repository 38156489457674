import React, { useMemo, useEffect, useState } from 'react';
import setCookie from '../services/cookie/setCookie';
import getCookie from '../services/cookie/getCookie';

const SiteContext = React.createContext();

export function SiteProvider(props) {

  const [domainHostname, setDomainHostname] = useState(null);
  const [action, setAction] = useState(null);

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (typeof window != 'undefined') {
      if (window.location.hostname.includes('stage.apply')) {
        setDomainHostname(window.location.hostname.replace('stage.apply.', 'stage.'));
      } else if (!window.location.hostname.includes('stage') && window.location.hostname.includes('apply')) {
        setDomainHostname(window.location.hostname.replace('apply.', 'www.'));
      } else {
        setDomainHostname(window.location.hostname);
      }
    }
  }, [domainHostname]);

  useEffect(() => {
    domainHostname != null && setAction(`${process.env.API_URL_AMAZON}/sites/data/${domainHostname === 'local.sem.living-us.com' ? 'local.living-us.com' : domainHostname}`);
  }, [domainHostname]);

  useEffect(() => {
    setLoading(true);
    if (domainHostname != null) {
      const fetchData = async () => {
        try {
          const response = await fetch(action);
          const json = await response.json();
          setResponse(json);
          setLoading(false);
        } catch (error) {
          setHasError(true);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [action]);

  const [data, setData] = useState({});
  const [forms, setForms] = useState({});
  const [gtmSite, setGtmSite] = useState();
  const [domainName, setDomainName] = useState();
  const [domainFolder, setDomainFolder] = useState();
  const [feeBiometrical, setFeeBiometrical] = useState();
  const [appName, setAppName] = useState();
  const [company, setCompany] = useState();
  const [siteTitle, setSiteTitle] = useState();
  const [siteDescription, setSiteDescription] = useState();
  const [siteCanonical, setSiteCanonical] = useState();
  const [siteRobots, setSiteRobots] = useState();
  const [siteFolder, setSiteFolder] = useState();

  useEffect(() => {
    if (response !== null) {

      if (getCookie('company') !== null && getCookie('company') !== 'undefined') {
        setCompany(JSON.parse(getCookie('company')));
      } else {
        setCompany(response.company);
        loading && setCookie('company', JSON.stringify(response.company), 0.2);
      }

      if (getCookie('forms') !== null && getCookie('forms') !== 'undefined') {
        setForms(JSON.parse(getCookie('forms')));
      } else {
        setForms(response.forms);
        loading && setCookie('forms', JSON.stringify(response.forms), 0.2);
      }

      if (getCookie('gtm') !== null && getCookie('gtm') !== 'undefined') {
        setGtmSite(getCookie('gtm'));
      } else {
        setGtmSite(response.gtm);
        loading && setCookie('gtm', response.gtm, 0.2);
      }

      if (getCookie('domain_simple') !== null && getCookie('domain_simple') !== 'undefined') {
        setDomainName(getCookie('domain_simple'));
      } else {
        setDomainName(response.domain_simple);
        loading && setCookie('domain_simple', response.domain_simple, 0.2);
      }

      if (getCookie('domain_folder') !== null && getCookie('domain_folder') !== 'undefined') {
        setDomainFolder(getCookie('domain_folder'));
      } else {
        setDomainFolder(response.domain_folder);
        loading && setCookie('domain_folder', response.domain_folder, 0.2);
      }

      if (getCookie('site_folder') !== null && getCookie('site_folder') !== 'undefined') {
        setSiteFolder(getCookie('site_folder'));
      } else {
        setSiteFolder(response.site_folder);
        loading && setCookie('site_folder', response.site_folder, 0.2);
      }

      if (getCookie('feeBiometrical') !== null && getCookie('feeBiometrical') !== 'undefined') {
        setFeeBiometrical(getCookie('feeBiometrical'));
      } else {
        setFeeBiometrical(response.configuration_data ? response.configuration_data.FEE_BIOMETRICAL_US : '');
        loading && setCookie('feeBiometrical', response.configuration_data ? response.configuration_data.FEE_BIOMETRICAL_US : '', 0.2);
      }

      if (getCookie('appName') !== null && getCookie('appName') !== 'undefined') {
        setAppName(getCookie('appName'));
      } else {
        setAppName(response.app_name ? response.app_name : '');
        loading && setCookie('appName', response.app_name ? response.app_name : '', 0.2);
      }

      if (getCookie('siteTitle') !== null && getCookie('siteTitle') !== 'undefined') {
        setSiteTitle(getCookie('siteTitle'));
      } else {
        setSiteTitle(response.configuration_data ? response.configuration_data.SITE_TITLE : '');
        loading && setCookie('siteTitle', response.configuration_data ? response.configuration_data.SITE_TITLE : '', 0.2);
      }

      if (getCookie('siteDescription') !== null && getCookie('siteDescription') !== 'undefined') {
        setSiteDescription(getCookie('siteDescription'));
      } else {
        setSiteDescription(response.configuration_data ? response.configuration_data.SITE_DESCRIPTION : '');
        loading && setCookie('siteDescription', response.configuration_data ? response.configuration_data.SITE_DESCRIPTION : '', 0.2);
      }

      if (getCookie('siteCanonical') !== null && getCookie('siteCanonical') !== 'undefined') {
        setSiteCanonical(getCookie('siteCanonical'));
      } else {
        setSiteCanonical(response.configuration_data ? response.configuration_data.SITE_CANONICAL : '');
        loading && setCookie('siteCanonical', response.configuration_data ? response.configuration_data.SITE_CANONICAL : '', 0.2);
      }

      if (getCookie('siteRobots') !== null && getCookie('siteRobots') !== 'undefined') {
        setSiteRobots(getCookie('siteRobots'));
      } else {
        setSiteRobots(response.configuration_data ? response.configuration_data.SITE_ROBOTS : '');
        loading && setCookie('siteRobots', response.configuration_data ? response.configuration_data.SITE_ROBOTS : '', 0.2);
      }

    }
  }, [loading, response]);

  const value = useMemo(() => {
    return {
      data,
      forms,
      gtmSite,
      domainName,
      domainFolder,
      feeBiometrical,
      appName,
      company,
      siteFolder,
      siteTitle,
      siteDescription,
      siteCanonical,
      siteRobots,
    };
  }, [data, forms, gtmSite, domainName, domainFolder, feeBiometrical, appName, company, siteFolder, siteTitle, siteDescription, siteCanonical, siteRobots]);

  return <SiteContext.Provider value={typeof domainName != 'undefined' ? value : null} {...props} />;
}

export function useSite() {
  const context = React.useContext(SiteContext);
  if (!context) {
    // throw new Error('error en context');
  }

  return context;
}

export default SiteContext;