import './src/style/global.scss';
import './src/style/themes/services/sticky.scss';

import * as React from 'react';
import { SiteProvider } from './src/context/siteContext';
import type { GatsbyBrowser } from 'gatsby';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <SiteProvider>
    {element}
  </SiteProvider>
);